import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import Home from './Home';
import Post from './post';
import article from './assets/article.png';
import home from './assets/home.png';
import review from './assets/review.png';
import articleb from './assets/articleb.png';
import homeb from './assets/homeb.png';
import reviewb from './assets/reviewb.png';

import btc from './assets/btc.png';
import cpp from './assets/cpp.png';
import spiderm from './assets/spiderm.png';
import rlogo from './assets/logo192.png';

import { article1, article2 } from './Articles';

import GoogleAds from './Components/GoogleAds';
import GoogleFixedads from './Components/GoogleFixedads';

const Container = () => {

    

    const circRef = useRef(null);

    const homeRef = useRef(null);
    const revRef = useRef(null);
    const artRef = useRef(null);

    const homeComponentRef = useRef(null);

    const [active, setActive] = useState(2);

    const [homeCheck, setHomeCheck] = useState('inline');
    const [post, setPost] = useState('hidden');
    const [article, setArticle] = useState('hidden');

    useEffect(() => {
        document.title = "TechByte"
     }, []);

     const ads = process.env.NODE_ENV === 'production' &&      <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" /> ;

     

    return (
        <div className="overflow-x-hidden w-screen h-screen flex ">
            <head>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452" crossorigin="anonymous"></script>
                {ads && ads}
            </head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
            <div className="z-50 w-[95%] fixed bg-[#494a54] h-[9%] top-[5%] left-[2.5%] rounded-[20px] drop-shadow-2xl flex justify-end min-h-[80px] max-h-[80px]">
                <ul className="flex items-center z-[3]">
                    <li className="transition duration-[1s] flex px-[30px]">
                        <input type="image" ref={homeRef} className="transition duration-[0.6s] h-[48px] " src={homeb} alt="" onClick={
                            () => {
                                circRef.current.classList.remove('translate-x-[108px]');
                                circRef.current.classList.remove('translate-x-[216px]');
                                circRef.current.classList.add('translate-x-[0px]');
                                
                                revRef.current.classList.remove('translate-y-[8px]');
                                artRef.current.classList.remove('translate-y-[8px]');
                                homeRef.current.classList.add('translate-y-[8px]');
                                
                                setPost('hidden');
                                setHomeCheck('inline');
                                setArticle('hidden');

                                setActive(2);
                                
                            }
                        } />
                    </li>
                    <li className="transition duration-[1s] flex px-[30px]">
                        <input type="image" ref={revRef} className="transition duration-[0.6s] h-[48px] " src={reviewb} alt="" onClick={
                            () => {
                                circRef.current.classList.remove('translate-x-[0px]');
                                circRef.current.classList.remove('translate-x-[216px]');
                                circRef.current.classList.add('translate-x-[108px]');

                                homeRef.current.classList.remove('translate-y-[8px]');
                                artRef.current.classList.remove('translate-y-[8px]');
                                revRef.current.classList.add('translate-y-[8px]');
                                
                                setPost('inline');
                                setHomeCheck('hidden');
                                setArticle('hidden');

                                setActive(1);
                            }
                        } />
                    </li>
                    <li className="transition duration-[1s] flex px-[30px]">
                        <input type="image" ref={artRef} className="transition duration-[0.6s] h-[48px] " src={articleb} alt="" onClick={
                            () => {
                                circRef.current.classList.remove('translate-x-[108px]');
                                circRef.current.classList.remove('translate-x-[0px]');
                                circRef.current.classList.add('translate-x-[216px]');

                                revRef.current.classList.remove('translate-y-[8px]');
                                homeRef.current.classList.remove('translate-y-[8px]');
                                artRef.current.classList.add('translate-y-[8px]');

                                setPost('hidden');
                                setHomeCheck('hidden');
                                setArticle('inline');

                                setActive(2);
                            }
                        } />
                    </li>
                </ul>
                <div className="transition duration-[0.4s] h-[64px] w-[64px] bg-[#E8E8E8] self-center absolute z-[2] rounded-[50%] right-[238px]" ref={circRef} />
            </div>
            <Home display={homeCheck} />
            
            <div className={`overflow-x-hidden absolute left-[2.5vw] flex flex-row top-[164px] justify-center flex-wrap w-[70%] adtwo:w-[97.5%] ${post}`}>
                <Post title="What is Crypto-currency and should you care?" color="yellow-500" image={btc} link='/Crypto-Explained' />
                <div className="hidden relative w-[95vw] h-[150px] custom:flex ">
                    <GoogleAds slot="9302081894" />
                </div>
                <Post title="Why C++ is the best programming language to start with" color="blue-500" image={cpp} link='/What-is-C++' />
                
                
                

            </div>

            <div className={`flex fixed right-[2.5vw] top-[180px] h-[70vh] w-[25%] adtwo:hidden items-center justify-center ${post}`}>
                <GoogleAds slot="2494003108" />
            </div>

            <div className={`overflow-x-hidden absolute left-[2.5vw] flex flex-row top-[164px] justify-center flex-wrap w-[70%] adtwo:w-[97.5%] ${article}`}>
                <Post title="Spiderman No Way Home: Does it Live up to the hype?" color="red-500" image={spiderm} link="/Spider-Man-NWH" />
                <div className="hidden relative w-[95vw] h-[150px] custom:flex ">
                    <GoogleAds slot="1913518798" />
                </div>
                
                
            </div>
            
            <div className={`flex fixed right-[2.5vw] top-[180px] h-[70vh] w-[25%] adtwo:hidden items-center justify-center ${article}`}>
                <GoogleAds slot="8337356722" />
            </div>

        </div>
    )
}

export default Container
