import React from 'react'
import back from '../assets/back.png';
import blockDia from '../assets/blockDia.png';
import cppcode from '../assets/cppcode.png'
import {
    Route,
    NavLink,
    Link
  } from "react-router-dom";
  import { BrowserRouter as Router } from 'react-router-dom';

import GoogleAds from '../Components/GoogleAds';
import GoogleFixedads from '../Components/GoogleFixedads';

const article1 = () => {
    return (
        <div className="bg-[rgb(32,32,48)] h-screen w-screen flex flex-col overflow-x-hidden" >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet" /> 
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
            
            <div className="fixed flex w-[90vw] md:h-[80px] h-[150px] justify-center items-center bg-[#494a54] rounded-xl shadow-2xl z-50 my-[30px] ml-[3vw]">
                    <h1 className="absolute flex h-[90%] w-[75%] right-[2%] top-[5%] font-['Montserrat'] text-white justify-center items-center text-[25px]" >
                        Why C++ is the best programming language to start with
                    </h1>
                    <div onClick={() => window.location.href = '/'} className="transition duration-[0.4s] absolute flex h-[90%] md:w-[10%] w-[21%] left-[2%] top-[5%] justify-center items-center cursor-pointer rounded-xl hover:bg-[#5f606d] ">
                        <img src={back} alt="" className="h-[60px] z-50 " />
                    </div>
                </div>
            <div className="relative flex self-center pb-[5vh] md:pt-[130px] pt-[200px]">
                        <p className="relative flex flex-col py-[5%] px-[5%] text-white font-[27px]  
                        bg-[#494a54] adthree:w-[90vw] w-[60vw] max-w-[780px] rounded-2xl ">
                            
                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; In a world with tons of different ways and languages to code computers, wanting in on this magic is tempting. But which one to start with? I’m here to tell you the option to start is definitely C++.

                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        <hr />
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p>
                            <img src={blockDia} alt="" className="w-[200px] h-[200px] mr-[10px] float-left" />
                            You see, C++ was created 36 years ago, in 1985. Its creator, Bjarne Stroustrup, started working on C++’s mother, C, or the monarch of all programming languages as some say, as most languages are compiled with C. But he was working on a different kind of C. C but with classes. Any experienced programmer out there is familiar with classes, but for others, if you create a class, you can then create instances, or objects, from that class. He called this specialized C language C++, as ++ is an increment operator in C, kind of as a play on words/symbols.
                            
                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        
                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp;  In many other languages, the computer's Random Access Memory, or RAM is automatically allocated to wherever it is most needed. However, C++ is like a primitive coding language in the way that memory must be specifically allocated to where it is needed, in the code. This allows the language to run faster, as memory isn’t used in places that aren’t needed, speeding up the application. This is why websites like Youtube or Google use C++. 

                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <div className=" w-[100%] h-[100px]">
                            <GoogleAds slot="" />
                        </div>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>


                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; But on to why C++ is the best starting programming language. Without having to explain how it is coded. When you start programming, normally you don’t have much of an idea of how a computer works or how code is compiled/run. C++ can give you a valuable understanding of how memory works. It is a language that needs the developer to allocate memory. C++ is also generally referred to as a variant or superset of C. Knowing C is extremely valuable when  learning other programming languages, because all languages are, in very basic terms, some abstraction or simplification of C. This knowledge of C comes in handy when coding, you’ll have insight into just the theory of programming, or how to solve this, or that. But these slight advantages pail in comparison to one of the biggest advantage of C: it’s really hard. While a basic ‘Hello World might look something like this in Python:
                            
                            <img src={cppcode} alt="" className="w-[100%] mr-[10px] float-right" />
                            
                        </p>
                        
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p>


                        <img src="https://miro.medium.com/max/1400/1*8j2PmhExz4q87OoddaH7ag.png" className="w-[200px] h-[157px] ml-[10px] float-right" />

                        You have to really think about everything you code in C++. So many things going on in the computer are completely controlled by this one program. You start off practicing this advanced syntax that keeps track of everything. Going from this to something like Javascript you will know how to make everything connect well because, you know how that connection is programmed. 

                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; That’s my opinion on the first language you should learn if you want to start coding. There are so many advantages you will have when trying other things or even connecting C++ to those other frameworks or files in your application. There are so many resources you can use online to begin. Good Luck!

                        </p>


                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        <hr />
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p className="cursor-pointer text-[27px] font-['Montserrat']"><span className="transition duration-[0.4s] hover:bg-[#5f606d] p-[2px] rounded ">Read More {'>'}</span></p>

                    </p>
                    
                    <div className="flex fixed">

                    </div>

                
            </div>
        </div>
    )
}

export default article1

