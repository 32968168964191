import React from 'react'
import back from '../assets/back.png';
import blockDia from '../assets/blockDia.png';
import {
    Route,
    NavLink
  } from "react-router-dom";
  import { BrowserRouter as Router } from 'react-router-dom';

import GoogleAds from '../Components/GoogleAds';
import GoogleFixedads from '../Components/GoogleFixedads';

const article1 = () => {
    return (
        <div className="bg-[rgb(32,32,48)] h-screen w-screen flex flex-col overflow-x-hidden" >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet" /> 
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>

                <div className="fixed flex w-[90vw] md:h-[80px] h-[150px] justify-center items-center bg-[#494a54] rounded-xl shadow-2xl z-50 my-[30px] ml-[3vw]">
                    <h1 className="absolute flex h-[90%] w-[75%] right-[2%] top-[5%] font-['Montserrat'] text-white justify-center items-center text-[25px]" >
                        What is Cryptocurrency and should you care?
                    </h1>
                    <div onClick={() => window.location.href = '/'} className="transition duration-[0.4s] absolute flex h-[90%] md:w-[10%] w-[21%] left-[2%] top-[5%] justify-center items-center cursor-pointer rounded-xl hover:bg-[#5f606d] ">
                        <img src={back} alt="" className="h-[60px] z-50 " />
                    </div>
                </div>
            
            <div className="relative flex self-center pb-[5vh] md:pt-[130px] pt-[200px]">
                        <p className="relative flex flex-col py-[5%] px-[5%] text-white font-[27px]  
                        bg-[#494a54] adthree:w-[90vw] w-[60vw] max-w-[780px] rounded-2xl ">
                            
                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; Unless you’ve been living under a rock for the past 5 years, you know what cryptocurrency is. And if you barely know what it is, you probably think it's some ‘Weird internet fad’ or a ‘Criminal Overhyped mess’ and that couldn’t be farther from the truth. I want to write about why Crypto is the future, and what it is.
                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        <hr />
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p>
                            <img src={blockDia} alt="" className="w-[200px] h-[200px] mr-[10px] float-left" />
                            Well the first concept you have to understand and a buzzword you’ve probably heard thrown around here and there is ‘The Blockchain’ but what does it do and why is it so innovative?
                            Well, the blockchain is less of a cool matrix kind of system and is just a ledger. You see, whenever you or I want to buy Bitcoin, you send out a request to the ‘system’ and from there, computers that ‘Mine Bitcoin’ verify your request, add a ‘block’ or a string of data to the chain, then receive bitcoin for verifying that transaction. The more computers that are ‘mining’ bitcoin, the more requests can be verified, the more bitcoin you can receive mining that bitcoin, and more blocks will be added to the chain. Now, because all of these computers are verifying all these transactions and contributing to the blockchain, it’s extremely hard to hack it.
                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        
                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; When I say extremely hard, I mean it. The computers verifying these transactions can see some of the information involved, but can’t change any of it. Every single block in this chain is protected by a random cryptograph only known by the owner, making it near impossible to hack. The only way to hack these bitcoins is by hacking some of the websites that facilitate these transactions like Coinbase. The blockchain has only been hacked a few times, and that was due to glitches on the companies fault, not the blockchain.
                        </p>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <div className=" w-[100%] h-[100px]">
                            <GoogleAds slot="" />
                        </div>

                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>


                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; All this security and how it works is interesting, but why is this the ‘currency of the future', and what is it backed by? Well, I have a theory and some facts. The truth is, these coins aren’t backed by anything, other than the fact that they are limited in quantity.  But maybe that’s a good thing. Now, most of the world's biggest currencies are backed by some physical resource. But imagine if that didn’t exist. Not only would we not have to waste resources on money, everyone's money and transactions would be completely private. You would still be taxed, of course, but near everything would be instant, and a massive framework would be supporting and 'mining' bitcoin stabilizing the market. Inflation would be nonexistent(once all the bitcoin is mined), also interest wouldn't even exist. I believe that as corporations realize the stability and advantages of crypto, they will begin incorporating it as payment for their services, or products. However, I think the main limitation for Bitcoin or cryptocurrency, in general, is its muddy reputation. People don't realize how useful it could be as a form of payment. That mainstream exposure could push crypto to the next step, I believe at least.
                        </p>
                        
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p>
                            &nbsp; &nbsp; &nbsp; &nbsp; All in all, I do think cryptocurrency will be the main form of payment in the future, due to a multitude of different variables and reasons. I think it will be amazing. 
                        </p>


                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                        <hr />
                        <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                        <p className="cursor-pointer text-[27px] font-['Montserrat']"><span className="transition duration-[0.4s] hover:bg-[#5f606d] p-[2px] rounded ">Read More {'>'}</span></p>

                    </p>
                    
                
            </div>
        </div>
    )
}

export default article1
