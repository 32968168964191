import React, { useState, useRef } from 'react';
import article from './assets/article.png';
import home from './assets/home.png';
import review from './assets/review.png';
import articleb from './assets/articleb.png';
import homeb from './assets/homeb.png';
import reviewb from './assets/reviewb.png';

import btc from './assets/btc.png';

import { Link, NavLink, Routes, Route } from 'react-router-dom';
import { article1 } from './Articles';

class Post extends React.Component {
    constructor(props) {
        super(props);
    }
    render () {
    return (
        <div className={`z-[5] cursor-pointer p-[1rem] relative flex ${this.props.display}`}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
            <div className="relative rounded-2xl shadow-2xl bg-[#494a54] md:h-[400px] md:w-[300px] w-[95vw] h-[400px] ">
                <Link to={this.props.link} className="absolute w-full h-full z-[99]">
                    <div key={this.props.color} className={`z-50 transition-all duration-[0.8s] flex h-full left-0 top-0 w-[50%] justify-center items-center custom:rounded-l-2xl md:rounded-t-2xl md:w-full md:h-[50%] md:hover:h-[60%] md:hover:w-full bg-gradient-to-b from-black to-green-500`}>
                        <img src={this.props.image} className="noimg:hidden w-[110px] drop-shadow-[20px_35px_0px_rgba(0,0,0,0.25)] " alt="" />
                    </div>
                    <div className="flex absolute md:hidden text-white right-[3%] h-[80%] w-[35%] top-[10%] ">
                        <h1 className="flex text-[17px] font-['Montserrat'] justify-center">{this.props.title}</h1>
                    </div>
                    <div className="flex absolute custom:hidden text-white h-[40%] w-[80%] bottom-[3%] left-[5%] items-center justify-center ">
                        <h1 className="flex text-[24px] font-['Montserrat'] justify-center">{this.props.title}</h1>
                    </div>
                </Link>
            </div>
        </div>
    )}
}

export default Post
