import React, { useState, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link, NavLink, Routes, Route } from 'react-router-dom';
import article from './assets/article.png';
import home from './assets/home.png';
import review from './assets/review.png';
import articleb from './assets/articleb.png';
import homeb from './assets/homeb.png';
import reviewb from './assets/reviewb.png';

import btc from './assets/btc.png';
import cpp from './assets/cpp.png';
import spiderm from './assets/spiderm.png';
import rlogo from './assets/logo192.png';

import GoogleAds from './Components/GoogleAds';
import GoogleFixedads from './Components/GoogleFixedads';

export const Homepost = props => {
    return (
        <div className={`flex relative cursor-pointer px-[7px] h-[430px] py-[10px] z-[5]`}>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
            <div onClick={() => window.location.href=props.link} className="relative rounded-2xl shadow-2xl bg-[#494a54] md:h-[400px] md:w-[350px] w-[95vw] h-[400px] ">
                
                    <div key={props.color} className={`z-50 transition-all duration-[0.8s] flex h-full left-0 top-0 w-[50%] justify-center items-center custom:rounded-l-2xl md:rounded-t-2xl md:w-full md:h-[50%] md:hover:h-[60%] md:hover:w-full bg-gradient-to-b from-black to-red-500`}>
                        <img src={props.image} className="noimg:hidden w-[110px] drop-shadow-[20px_35px_0px_rgba(0,0,0,0.25)] " alt="" />
                    </div>
                    <div className="flex absolute md:hidden text-white right-[3%] h-[80%] w-[35%] top-[10%] ">
                        <h1 className="flex text-[17px] font-['Montserrat'] justify-center">{props.title}</h1>
                    </div>
                    <div className="flex absolute custom:hidden text-white h-[40%] w-[80%] bottom-[3%] left-[5%] items-center justify-center ">
                        <h1 className="flex text-[24px] font-['Montserrat'] justify-center">{props.title}</h1>
                    </div>
                
            </div>
        </div>
    )
}






const Home = props => {

    const ads = process.env.NODE_ENV === 'production' &&      <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />;

    

    return (
        <div className={`overflow-x-hidden justify-center relative w-screen h-screen flex flex-row content-start ${props.display}`}>
            <head>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452" crossorigin="anonymous"></script>
                {ads && ads}
            </head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet" /> 
            
            
            
            <div className="pt-[170px] md:w-[730px] flex md:flex-row md:flex-wrap flex-col content-start ">
                <Homepost image={btc} title="What is Cryptocurrency and should you care?" link="/Crypto-Explained" />
                <Homepost image={spiderm} title="Spider-Man No Way Home: Does it live up to the Hype?" link="/Spider-Man-NWH" />
                
                <div className="relative mx-[7px] my-[20px] h-[150px] z-[5] hidden even:inline w-[716px] ">
                    <GoogleFixedads
                        slot="3695404137"                  
                        style={{
                            display: 'inline-block',
                            width: '716px',                  
                            height: '150px'                  
                        }}
                    />
                </div>
                

                <Homepost image={cpp} title="Why C++ is the best programming language to start with" link="/What-is-C++" />
                <Homepost image={rlogo} title="Website Launched!" />

            </div>

            <div className="flex relative mx-[20px]  mt-[200px] hidden even:hidden md:inline h-[770px] w-[200px]">
                <GoogleFixedads
                    slot="5399368049"                  
                    style={{
                        display: 'inline-block',
                        width: '200px',                  
                        height: '781px'                  
                    }}
                />
            </div>
            
            
            
            
        </div>
        )
    }

export default Home