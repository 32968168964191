import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Article1, Article2, Review1 } from './Articles';
import Container from './container';


const App = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Container />} />
            <Route exact path="/Crypto-Explained" element={<Article1 />} />
            <Route exact path="/What-is-C++" element={<Article2 />} />
            <Route exact path="/Spider-Man-NWH" element={<Review1 />} />
        </Routes>
    )
}

export default App
