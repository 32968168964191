import React from 'react'
    import back from '../assets/back.png';
    import blockDia from '../assets/blockDia.png';
    import cppcode from '../assets/cppcode.png'

    import GoogleAds from '../Components/GoogleAds';
    import GoogleFixedads from '../Components/GoogleFixedads';

    import {
        Route,
        NavLink
      } from "react-router-dom";
      import { BrowserRouter as Router } from 'react-router-dom';
    
    const article1 = () => {
        return (
            <div className="bg-[rgb(32,32,48)] h-screen w-screen flex flex-col overflow-x-hidden" >
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
                crossorigin="anonymous"></script>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet" /> 
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7656490575236452"
            crossorigin="anonymous"></script>
                <div className="py-[30px] px-[5vw] relative z-50">
                    <div className="fixed flex w-[90vw] md:h-[80px] h-[150px] justify-center items-center bg-[#494a54] rounded-xl shadow-2xl">
                        <h1 className="absolute flex h-[90%] w-[75%] right-[2%] top-[5%] font-['Montserrat'] text-white justify-center items-center text-[25px]" >
                            Spider-Man No Way Home: Does it live up to the Hype?
                        </h1>
                        <button onClick={() => window.location.href = '/'} className="transition duration-[0.4s] absolute flex h-[90%] md:w-[10%] w-[21%] left-[2%] top-[5%] md:justify-center justify-start items-center cursor-pointer rounded-xl hover:bg-[#5f606d] ">
                            <img src={back} alt="" className="h-[60px] z-50 " />
                        </button>
                    </div>
                </div>
                <div className="flex self-center md:pt-[80px] pt-[170px]">
                    <div className="flex flex-col relative bg-[#ac2121] rounded-xl md:w-[80vw] w-[90vw] h-[180px] justify-center">
                        
                        <h1 className="flex self-center font-['Montserrat'] text-white text-[40px] pb-[10px]">
                            9.6/10
                        </h1>

                        <div className="flex relative self-center bg-[#494a54] h-[10px] w-[40%] rounded-md">

                            <div className="flex absolute left-0 bg-[#ffff78] h-full w-[96%] rounded-md">

                            </div>

                        </div>

                        <img className="absolute left-[2vw] h-[80%]" src="" />

                    </div>
                </div>
                <div className="relative flex self-center pb-[5vh] md:pt-[80px] pt-[80px]">
                            <p className="relative flex flex-col py-[5%] px-[5%] text-white font-[27px]  
                            bg-[#494a54] adthree:w-[90vw] w-[60vw] max-w-[780px] rounded-2xl ">
                                
                                <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

                            <div className=" w-[100%] h-[100px]">
                                <GoogleAds slot="" />
                            </div>

                            <p>

            &nbsp; &nbsp; &nbsp; &nbsp; I saw the new spiderman movie a few days ago and I'm amazed at just how good it was. I won't go into spoilers, but I will talk about how I feel about it. I love the MCU, and I love a lot of their movies. I am so hyped for all the new releases we're getting over the next 2 or so years, but this movie was probably the one I was most excited for. And like a lot of people, I was very worried it wouldn't be good. But I was so wrong. The messages and themes of this movie were some of the best that I've seen in a Marvel movie, let alone a movie made in the past 3 years. The constant struggle of the main character I feel really helped connect Spider-man to the audience. I know that's what it did for me. I have never seen a theater so loud so many times. This movie made me forget about all my problems for 2 hours and 40 minutes. I wholeheartedly give it a 9.6/10, and I think it is the best spider-man movie. (Not counting Spider-Verse, of course)

        </p>
        
    
                            <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
                            <hr />
                            <p>&nbsp; &nbsp; &nbsp; &nbsp;</p>
    
                            <p className="cursor-pointer text-[27px] font-['Montserrat']"><span className="transition duration-[0.4s] hover:bg-[#5f606d] p-[2px] rounded ">Read More {'>'}</span></p>
    
                        </p>
                        
                    
                </div>
            </div>
        )
    }
    
    export default article1